export const paswordAllowedLength = 8
export const headerHeight = 80
export const CHAT_INPUT_HEIGHT = 60
export const CHAT_BUTTON_SIZE = 40
export const SIDE_BAR_WIDTH = 300
export const CONVO_IMG_WIDTH = 50
export const YOUTUBE_STRING_PRE = 'https://www.youtube.com/watch?v='
export const YOUTUBE_STRING_PRE2 = 'https://youtu.be/'
export const contactEmail = 'contact@youtranscribe.com'
export const instagramLink = 'https://www.instagram.com/youtranscribe.com'
export const facebookLink = 'https://www.facebook.com/people/Pixel-Shades/100087884742930/'
export const linkedInLink = 'https://www.linkedin.com/company/pixel-shades/'
export const appCurrency = '€'
export const appColors = {
  primary: '#f0361f',
  secondary: '#26d7f9',
  darkGreen: '#486837',
  dark: '#2c2c2c',
}

export const backendErrors = {
  textTooLong: 'long-text',
  emailExists: 'email-exists',
  wrongLogin: 'wrong-email-or-password',
  wrongCurrentPass: 'current-password-wrong',
}
