import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AuthState, emptyAuthState } from './Types/AuthState'
import { login, signOut, sendRegistration, sendPasswordForgottenEmail } from './Auth.interactor'
import { persistTokens, removeTokens } from 'Config/TokensMaster'
import { isProduction } from 'Config/envUtils'

const authSlice = createSlice({
  name: 'auth',
  initialState: emptyAuthState,
  reducers: {
    initializeState: (state) => {
      state = { ...emptyAuthState }
    },
    setStateTokens: (state, action: PayloadAction<AuthState | null>) => {
      state.accessToken = action.payload?.accessToken ?? null
      state.renewToken = action.payload?.renewToken ?? null
      state.accessTokenExpiryTime = action.payload?.accessTokenExpiryTime ?? null
      state.renewTokenExpiryTime = action.payload?.renewTokenExpiryTime ?? null
      if (!isProduction) console.log('New auth state:', JSON.parse(JSON.stringify(state, null, 2)))
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(sendRegistration.pending, (state, action) => {})
      // .addCase(sendRegistration.rejected, (state, action) => {})
      .addCase(sendRegistration.fulfilled, (state: AuthState, { payload }) => {
        persistTokens(payload.data)
        state.accessToken = payload.data.accessToken
        state.renewToken = payload.data.renewToken
        state.accessTokenExpiryTime = payload.data.accessTokenExpiryTime
        state.renewTokenExpiryTime = payload.data.renewTokenExpiryTime
        if (!isProduction) console.log('New auth state:', JSON.parse(JSON.stringify(state, null, 2)))
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        persistTokens(payload.data)
        state.accessToken = payload.data.accessToken
        state.renewToken = payload.data.renewToken
        state.accessTokenExpiryTime = payload.data.accessTokenExpiryTime
        state.renewTokenExpiryTime = payload.data.renewTokenExpiryTime
        if (!isProduction) console.log('New auth state:', JSON.parse(JSON.stringify(state, null, 2)))
      })
      // .addCase(login.pending, (state, action) => {})
      // .addCase(login.rejected, (state, action) => {})
      .addCase(signOut.pending, (state, action) => {})
      .addCase(signOut.fulfilled, (state, { payload }) => {
        removeTokens()
        state.accessToken = null
        state.renewToken = null
        state.accessTokenExpiryTime = null
        state.renewTokenExpiryTime = null
        if (!isProduction) console.log('New auth state:', JSON.parse(JSON.stringify(state, null, 2)))
      })
      .addCase(signOut.rejected, (state, action) => {})
  },
})

export const authActions = {
  ...authSlice.actions,
  signOut,
  sendRegistration,
  login,
  sendPasswordForgottenEmail,
}
export default authSlice.reducer
