import React, { createContext, useContext, useState, ReactNode } from "react";

interface OverlayContextProps {
  children: ReactNode;
}

interface OverlayContextType {
  isOpen: boolean;
  overlayContent: ReactNode;
  setOverlayContent: (content: ReactNode) => void;
  openOverlay: () => void;
  closeOverlay: () => void;
}

const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

export const OverlayProvider: React.FC<OverlayContextProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [overlayContent, setOverlayContent] = useState<ReactNode | null>(null);

  const openOverlay = () => {
    setIsOpen(true);
  };

  const closeOverlay = () => {
    setOverlayContent(null);
    setIsOpen(false);
  };

  const value: OverlayContextType = {
    isOpen,
    overlayContent,
    setOverlayContent,
    openOverlay,
    closeOverlay,
  };

  return (
    <OverlayContext.Provider value={value}>
      {children}
      {overlayContent}
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (!context) {
    throw new Error("useOverlay must be used within an OverlayProvider");
  }
  return context;
};
