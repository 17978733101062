import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { AppButton } from "./AppButton";
import { AppTitle } from "./AppTitle";

export const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <PageNotFoundWrapper>
      <div className="centered">
        <AppTitle text={t("not-found.title")} />
        <AppButton text={t("not-found.button")} width={300} to="/" />
      </div>
    </PageNotFoundWrapper>
  );
};

const PageNotFoundWrapper = styled.div`
  background-color: var(--dark-primary);
  width: 100%;
  color: white;
  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  @media only screen and (max-width: 1100px) {
  }
`;
