import { styled } from "styled-components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { additionalPages, conversationPages } from "../Config/RouteConfig";
import { Footer } from "./Footer";
import { Logo } from "./Logo";
import { Avatar } from "./Avatar";
import { SIDE_BAR_WIDTH, headerHeight } from "Config/Constants";

export const SideBar = () => {
  const { t } = useTranslation();
  return (
    <SideBarWrapper className="sidebar-container">
      <div className="logo-container">
        <Logo to="/" />
      </div>
      <div className="convos">
        {conversationPages.map((page, i) => (
          <NavLink
            key={`SideBar-link-${i}`}
            to={page.path}
            className={"sidebar-link"}
          >
            <Avatar img={page.img} name={page.name} />
            <span>{t(page.name)}</span>
          </NavLink>
        ))}
      </div>
      <div className="sidebar-footer">
        {additionalPages.map((page, i) => (
          <NavLink
            key={`SideBar-link-${i}`}
            to={page.path}
            className={"sidebar-link"}
          >
            <span>{t(page.name)}</span>
          </NavLink>
        ))}
        <Footer />
      </div>
    </SideBarWrapper>
  );
};

const SideBarWrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: ${SIDE_BAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: var(--dark);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 4;
  * {
    color: var(--white);
  }
  .logo-container {
    height: ${headerHeight}px;
    margin: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .convos {
    flex: 1;
  }
  .convos,
  .sidebar-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
  }
  .sidebar-footer {
    text-align: center;
  }
  .sidebar-link {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--white);
    padding: 0 5px;
    span {
      flex: 1;
      padding: 10px;
    }
  }
  .sidebar-link,
  .sidebar-link:hover {
    color: var(--white);
    text-decoration: none;
    text-transform: uppercase;
    font-family: var(--mont);
  }
  a.active {
    background-color: var(--dark-primary);
  }

  @keyframes gradientRotation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 400% 400%;
    }
  }

  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;
