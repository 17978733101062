import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { isProduction } from './Config/envUtils'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)

if (!isProduction) {
  // Log performance metrics
  reportWebVitals((metric) => {
    console.log(`fayfay ${metric.name}: ${metric.value}`)
  })
}
