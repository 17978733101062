import { ConversionItem, OperationType } from 'Contexts/General/Types/ConversionItem'

export const getLocalHistory = (): ConversionItem[] => {
  const history = localStorage.getItem('activityHistory')
  return history ? JSON.parse(history) : []
}

export const addToLocalHistory = (item: ConversionItem) => {
  const history = getLocalHistory()
  const existingIndex = history.findIndex((i) => i.resultFilePath === item.resultFilePath)
  if (existingIndex !== -1) {
    const existingItem = history[existingIndex]
    const updatedItem = {
      ...existingItem,
      ...Object.fromEntries(Object.entries(item).filter(([_, v]) => v !== undefined)),
    }
    history[existingIndex] = updatedItem
  } else {
    history.push(item)
  }
  localStorage.setItem('activityHistory', JSON.stringify(history))
}

export const clearLocalHistory = () => {
  localStorage.removeItem('activityHistory')
}
