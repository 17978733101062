import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import convertInstructor from 'Assets/Mascots/mascot-04.jpg'
import { useEffect, useState } from 'react'
import { fileToTranscript } from 'Contexts/General/General.interactor'
import { PageHeader } from 'Common/PageHeader'
import { ChatWindow } from 'Common/ChatWindow'
import { ChatBubble } from 'Common/ChatBubble'
import { Link } from 'react-router-dom'
import { ConversionItem, OperationType, SourceType } from 'Contexts/General/Types/ConversionItem'
import { isFileTooBig, sleep } from 'Config/functions'
import { ChatFileInput } from 'Common/ChatFileInput'
import { sendAnalyticsEvent } from 'Common/Analytics'
import { addToLocalHistory } from 'Utils/localStorageUtils'

export const TranscribeFileToTxt = () => {
  const { t } = useTranslation()
  const [userFile, setUserFile] = useState<File | null>()
  const [systemIsTyping, setSystemIsTyping] = useState<boolean>(false)
  const restartChatBubbles = [<ChatBubble content={t(`transcribe-file.msg-02`)} />]
  const [chatBubbles, setChatBubbles] = useState<JSX.Element[]>([
    <ChatBubble content={t(`transcribe-file.msg-01`)} />,
    ...restartChatBubbles,
  ])
  useEffect(() => {
    if (userFile) {
      checkFileSize()
    }
  }, [userFile])

  const checkFileSize = async () => {
    if (userFile) {
      if (isFileTooBig(userFile.size)) {
        await addMessage(t(`general.error-big-file`), true)
        restart()
      }
    }
  }

  const restart = () => {
    setSystemIsTyping(false)
    setUserFile(undefined)
    setChatBubbles((prev) => [...prev, ...restartChatBubbles])
  }

  const displayDownloadLink = async (newItem: ConversionItem) => {
    if (!newItem?.resultFilePath) return
    await sleep(300)
    await addMessage(t(`general.success`), true)
    await sleep(300)
    setChatBubbles((prev) => [
      ...prev,
      <ChatBubble
        content={
          <span>
            {t(`convert-file.msg-convert-success`)}
            <Link to={`/downloads`} className="download-link">
              {newItem?.title}
            </Link>
          </span>
        }
        isSystem
      />,
    ])
    sendAnalyticsEvent({
      category: 'file',
      action: OperationType.TRANSCRIPTION,
      fileName: newItem?.resultFilePath,
    })
    addToLocalHistory(newItem)
  }

  const addMessage = async (newMsg: string | JSX.Element, isSystem: boolean | undefined = false) => {
    if (isSystem) await sleep(300)
    setChatBubbles((prev) => [...prev, <ChatBubble content={newMsg} isSystem={isSystem} />])
    const element = document.getElementById('scroll-ref')
    if (element) {
      window.scrollTo(0, window.scrollY - 1150)
    }
  }

  const uploadUserFile = async () => {
    if (!userFile) return alert('no user file')
    const msg = userFile?.name
    await addMessage(msg)
    if (isFileTooBig(userFile.size)) {
      await addMessage(t(`general.error-big-file`), true)
      restart()
    } else {
      startConvert(userFile)
    }
  }

  const startConvert = async (file: File) => {
    setSystemIsTyping(true)
    try {
      const res = await fileToTranscript(file)
      if (res.error || !res.data) throw new Error(res.error?.message || 'Unknown error at startConvert')
      const newItem: ConversionItem = {
        originalFileName: userFile?.name,
        title: res.data.fileName,
        resultFilePath: res.data.filePath,
        size: res.data.fileSize,
        operationType: OperationType.TRANSCRIPTION,
        sourceType: SourceType.FILE,
        success: true,
        date: new Date().toISOString(),
      }
      await displayDownloadLink(newItem)
    } catch (error) {
      await addMessage(t(`general.error-wrong-try-again`), true)
      restart()
    } finally {
      setSystemIsTyping(false)
    }
  }

  return (
    <TranscribeFileToTxtWrapper>
      <PageHeader instructor={convertInstructor} title={t(`transcribe-file.page-title`)} />
      <ChatWindow systemIsTyping={systemIsTyping} chatBubbles={chatBubbles} />
      <ChatFileInput
        fileType="audio/*,video/*"
        userFile={userFile}
        setUserFile={setUserFile}
        uploadUserFile={uploadUserFile}
        restart={restart}
      />
    </TranscribeFileToTxtWrapper>
  )
}

const TranscribeFileToTxtWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1100px) {
  }
`
