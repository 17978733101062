/* eslint-disable no-useless-escape */
import { appCurrency, paswordAllowedLength } from './Constants'

export const randomIntFromInterval = (min = 0, max = 10) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function isStrongPassword(password?: string): boolean {
  if (!password) return false
  // Define regular expressions for each required condition
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasDigit = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)

  // Check if all conditions are met
  const isLengthValid = password.length >= paswordAllowedLength
  const isStrong = hasUppercase && hasLowercase && hasDigit && hasSpecialChar

  return isLengthValid && isStrong
}

export const isValidEmail = (email: string | undefined) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (typeof email === 'string' && email.length) {
    return re.test(String(email).toLowerCase().trim())
  }
  return false
}

export function importAll(r: any) {
  return r.keys().map(r)
}
export function writePrice(price: number) {
  return `${price} ${appCurrency}`
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export async function sleep(duration: number) {
  await delay(duration)
}

export const isValidYoutubeLink = (link: string): boolean => {
  if (link.includes('search_query')) return false
  // Trim the input link to remove leading and trailing whitespaces
  const trimmedLink: string = link.trim()

  // Regular expression pattern for matching YouTube URLs (protocol agnostic, case-insensitive)
  const youtubePattern =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/i

  // Check if the trimmed link matches the YouTube URL pattern
  const match: RegExpMatchArray | null = trimmedLink.match(youtubePattern)
  // Return true if there is a match, indicating a valid YouTube link
  return !!match
}

const audioTypes = ['.mp3', '.wav', '.ogg', '.aac', '.flac', '.wma', '.m4a']
const videoTypes = ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.flv', '.webm']
const textTypes = ['.txt', '.doc', '.docx', '.pdf']

export function checkIfValidFile(fileName: string, type: string | undefined = undefined): boolean {
  let validExtensions: string[] = []
  if (type?.includes('audio')) validExtensions = [...validExtensions, ...audioTypes]
  if (type?.includes('video')) validExtensions = [...validExtensions, ...videoTypes]
  if (type?.includes('text')) validExtensions = [...validExtensions, ...textTypes]

  const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase()

  return validExtensions.includes(`.${fileExtension}`)
}

export function isFileTooBig(fileSizeInBytes: number): boolean {
  // Convert bytes to gigabytes
  const fileSizeInGB = fileSizeInBytes / (1024 * 1024 * 1024)

  // Check if the file size is greater than 2 GB
  return fileSizeInGB > 2
}

export const formatFileSize = (size: number): string => {
  if (size >= 1e9) return (size / 1e9).toFixed(2) + ' GB'
  if (size >= 1e6) return (size / 1e6).toFixed(2) + ' MB'
  if (size >= 1e3) return (size / 1e3).toFixed(2) + ' KB'
  return size + ' Bytes'
}
