export interface AuthState {
  accessToken: string | null
  renewToken: string | null
  accessTokenExpiryTime: string | null
  renewTokenExpiryTime: string | null
}

export const emptyAuthState: AuthState = {
  accessToken: null,
  renewToken: null,
  accessTokenExpiryTime: null,
  renewTokenExpiryTime: null,
}
