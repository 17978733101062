import { styled } from "styled-components";

interface ChatBubbleProps {
  isSystem?: boolean;
  content: JSX.Element | string;
}
export const ChatBubble = ({ isSystem = true, content }: ChatBubbleProps) => {
  return (
    <ChatBubbleWrapper className={isSystem ? "system" : ""}>
      <div className="bubble">{content}</div>
    </ChatBubbleWrapper>
  );
};

const ChatBubbleWrapper = styled.div`
  margin-bottom: 10px;
  justify-content: end;
  display: flex;
  z-index: 0;
  span {
    color: white;
  }
  .bubble {
    max-width: 100px;
    z-index: 1;
  }
  &.system {
    justify-content: start;
    .bubble {
      background-color: var(--primary);
      color: var(--white);
      word-wrap: anywhere;
    }
  }
  .bubble {
    color: var(--black);
    max-width: 80%;
    background-color: var(--light-green);
    padding: 10px;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--bg-transparent);
    word-wrap: anywhere;
  }
  a {
    color: yellow !important;
  }
  @media only screen and (max-width: 1100px) {
  }
`;
