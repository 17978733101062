import { AppRoute } from '../Contexts/General/Types/AppRoute'
import { Tos } from '../Pages/About/Tos'
import { Downloads } from 'Pages/Downloads/Downloads'
import { ConvertUrlToMp3 } from 'Pages/Conversations/ConvertUrlToMp3'
import { TranscribeUrlToTxt } from 'Pages/Conversations/TranscribeUrlToTxt'
import mascot01 from 'Assets/Mascots/mascot-01.jpg'
import mascot02 from 'Assets/Mascots/mascot-02.jpg'
import mascot03 from 'Assets/Mascots/mascot-03.jpg'
import mascot04 from 'Assets/Mascots/mascot-04.jpg'
import mascot05 from 'Assets/Mascots/mascot-05.jpg'
import mascot06 from 'Assets/Mascots/mascot-06.jpg'
import { ConvertFileToMp3 } from 'Pages/Conversations/ConvertFileToMp3'
import { TranscribeFileToTxt } from 'Pages/Conversations/TranscribeFileToTxt'
import { SummarizeUrlToTxt } from 'Pages/Conversations/SummarizeUrlToTxt'
import { SummarizeFileToTxt } from 'Pages/Conversations/SummarizeFileToTxt'

export const additionalPages: AppRoute[] = [
  {
    name: `downloads-page.menu-title`,
    path: '/downloads',
    element: <Downloads />,
  },
  {
    name: `terms.menu-title`,
    path: '/terms-of-use',
    element: <Tos />,
  },
]

export const conversationPages: AppRoute[] = [
  // {
  //   name: `account.menu-title`,
  //   path: '/account',
  //   element: <Account />,
  //   img: accountPic,
  // },
  // {
  //   name: `convert-url.menu-title`,
  //   path: '/',
  //   element: <ConvertUrlToMp3 />,
  //   img: mascot01,
  // },
  {
    name: `convert-file.menu-title`,
    path: '/',
    // path: '/convert-file',
    element: <ConvertFileToMp3 />,
    img: mascot02,
  },
  // {
  //   name: `transcribe-url.menu-title`,
  //   path: '/transcribe-url',
  //   element: <TranscribeUrlToTxt />,
  //   img: mascot03,
  // },
  {
    name: `transcribe-file.menu-title`,
    path: '/transcribe-file',
    element: <TranscribeFileToTxt />,
    img: mascot04,
  },
  // {
  //   name: `summarize-url.menu-title`,
  //   path: '/summarize-url',
  //   element: <SummarizeUrlToTxt />,
  //   img: mascot05,
  // },
  {
    name: `summarize-file.menu-title`,
    path: '/summarize-file',
    element: <SummarizeFileToTxt />,
    img: mascot06,
  },
]

export const routerPages: AppRoute[] = [...conversationPages, ...additionalPages]
