/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore, Dispatch, Middleware } from '@reduxjs/toolkit'
import userReducer from '../Contexts/User/User.reducer'
import authReducer from '../Contexts/Auth/Auth.reducer'
import { isProduction } from './envUtils'

export const logger: Middleware<any, any, Dispatch> = () => (next) => (action) => {
  // Your middleware logic here
  if (!isProduction) {
    console.group(`------ACTION: ${`${((action as any) ?? '')?.type}`.toUpperCase()}------`)
    console.log('PREVIOUS STATE')
    console.log(action)
    // console.log('NEXT STATE')
    // console.log(store.getState())
    console.groupEnd()
  }

  // Call the next middleware in the chain (or the reducer if this is the last middleware)
  return next(action)
}
export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
  },
  devTools: !isProduction,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
