/* eslint-disable no-unused-vars */
export enum OperationType {
  DOWNLOAD = 'download',
  CONVERSION = 'conversion',
  SUMMARY = 'summary',
  TRANSCRIPTION = 'transcription',
}
export enum SourceType {
  YOUTUBE = 'youtube',
  FILE = 'file',
  TEXT_FILE = 'text_file',
  AUDIO = 'audio',
}

export interface ConversionItem {
  url?: string
  originalFileName?: string
  title?: string
  operationType?: OperationType
  sourceType?: SourceType
  size?: number
  success?: boolean
  date?: string
  resultFilePath?: string
}
