import axios, { InternalAxiosRequestConfig } from 'axios'
import { baseURL } from './envUtils'

const defaultOptions = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
}

export const fetcher = axios.create(defaultOptions)

fetcher.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
  const token = localStorage.getItem('accessToken')
  config.headers['Authorization'] = token ? `${token}` : ''
  return config
})
