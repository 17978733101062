import { styled } from "styled-components";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <p>{t("footer.rights")}</p>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: var(--dark);
  p {
    text-align: center;
    color: var(--white);
    font-size: 12px;
    margin: 12px;
  }
`;
