import { styled } from "styled-components";
import pattern from "../Assets/Images/cat-emoji-pattern.png";
import {
  CHAT_INPUT_HEIGHT,
  SIDE_BAR_WIDTH,
  headerHeight,
} from "Config/Constants";
import { useRef, useEffect } from "react";
import { ChatBubble } from "./ChatBubble";
import { IsTypingAnim } from "./IsTypingAnim";

interface ChatWindowProps {
  systemIsTyping: boolean;
  chatBubbles: JSX.Element[];
}
export const ChatWindow = ({
  systemIsTyping,
  chatBubbles,
}: ChatWindowProps) => {
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<any>();
    useEffect(() => elementRef?.current.scrollIntoView({ behavior: "smooth" }));
    return <div ref={elementRef} />;
  };

  return (
    <ChatWindowWrapper>
      {chatBubbles.map((msg, i) => ({
        ...msg,
        key: i as any,
      }))}
      {systemIsTyping && <ChatBubble content={<IsTypingAnim />} />}
      <AlwaysScrollToBottom />
      <div className="bg" />
    </ChatWindowWrapper>
  );
};

const ChatWindowWrapper = styled.section`
  position: relative;
  max-height: calc(100vh - ${CHAT_INPUT_HEIGHT}px - ${headerHeight}px);
  background-color: var(--light-grey);
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding: 20px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  flex: 1;
  /* flex-grow: 0; */
  /* flex-shrink: 0; */
  /* width: calc(100%-${SIDE_BAR_WIDTH}px); */

  z-index: 1;
  overflow-y: scroll;
  * {
    z-index: 1;
  }
  .bg {
    background-image: url(${pattern});
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1 !important;
    top: 0;
    left: 0;
    opacity: 0.1;
  }
  @media only screen and (max-width: 1100px) {
  }
`;
