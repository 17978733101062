import { styled } from "styled-components";
import { CONVO_IMG_WIDTH } from "Config/Constants";
import { randomIntFromInterval } from "Config/functions";
import { useOverlay } from "./Overlay";
import { IoMdCloseCircle } from "react-icons/io";

interface AvatarProps {
  img?: string;
  name?: string;
  width?: number;
  clickable?: boolean;
}
export const Avatar = ({ img, name, width, clickable }: AvatarProps) => {
  const { setOverlayContent, openOverlay, closeOverlay } = useOverlay();

  const clicked = () => {
    if (!clickable) return;

    setOverlayContent(
      <BigAvatarWrapper>
        <div className="mid-container">
          <IoMdCloseCircle
            fill="white"
            color="white"
            size={30}
            onClick={closeOverlay}
          />
          <img src={img} alt={name ?? "z-avatar"} />
        </div>
        <div className="bg" onClick={closeOverlay} />
      </BigAvatarWrapper>
    );
    openOverlay();
  };

  if (!img) return null;
  return (
    <AvatarWrapper>
      <div
        onClick={clicked}
        className="pdp-container"
        style={width ? { width: width, height: width } : {}}
      >
        <img src={img} alt={name ?? "avatar"} />
      </div>
    </AvatarWrapper>
  );
};

const BigAvatarWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  .bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-transparent);
    z-index: 21;
  }
  .mid-container {
    z-index: 22;
    position: relative;
    width: 95%;
    max-height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    max-width: 500px;
    max-height: 500px;
    border: 2px solid var(--white);
    border-radius: 12px;
    overflow: hidden;
    svg {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;
const AvatarWrapper = styled.header`
  .pdp-container {
    cursor: pointer;
    width: ${CONVO_IMG_WIDTH}px;
    height: ${CONVO_IMG_WIDTH}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    padding: 2px;
    background: rgb(255, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 237, 0, 1) 21%,
      rgba(35, 125, 46, 1) 31%,
      rgba(0, 68, 203, 1) 50%,
      rgba(255, 237, 0, 1) 71%,
      rgba(35, 125, 46, 1) 81%,
      rgba(255, 0, 0, 1) 100%
    );
    background-size: 400% 400%;
    animation: gradientRotation 20s infinite linear;
    /* animation-delay: ${randomIntFromInterval()}s; */

    img {
      border-radius: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 1100px) {
  }
`;
