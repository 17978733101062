export interface User {
  admin?: boolean
  email: string
  firstName: string
  lastName: string
  emailNotificationsOn: boolean
}

export const emptyUser: User = {
  admin: false,
  email: '',
  firstName: '',
  lastName: '',
  emailNotificationsOn: true,
}

export const quickLogin = {
  email: 'user1@example.com',
  password: 'Password-00',
}
