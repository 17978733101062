import { styled } from 'styled-components'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { PageNotFound } from './PageNotFound'
import { routerPages } from '../Config/RouteConfig'
import { SideBar } from './SideBar'
import { ConvertUrlToMp3 } from 'Pages/Conversations/ConvertUrlToMp3'
import { getTokens, isJWTValid, removeTokens } from 'Config/TokensMaster'
import { AppDispatch } from 'Config/store'
import { useDispatch } from 'react-redux'
import { authActions } from 'Contexts/Auth/Auth.reducer'
import { emptyAuthState } from 'Contexts/Auth/Types/AuthState'
import { ConvertFileToMp3 } from 'Pages/Conversations/ConvertFileToMp3'

export const Navigator = () => {
  const history = useLocation()
  const dispatch: AppDispatch = useDispatch()
  const authTokens = getTokens()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [history.pathname])

  useEffect(() => {
    isJWTValid({
      token: authTokens.accessToken,
      refresh: authTokens.renewToken,
    }).then((isValid) => {
      if (!isValid) {
        dispatch(authActions.setStateTokens(emptyAuthState))
        removeTokens()
      } else {
        dispatch(authActions.setStateTokens(authTokens))
      }
    })
  }, [authTokens, dispatch])

  return (
    <NavigatorWrapper>
      <SideBar />
      <div className="main-content">
        <Routes>
          <Route index element={<ConvertFileToMp3 />} />
          {routerPages.map((page, i) => (
            <Route key={`route-${i}`} path={page.path} element={page.element} />
          ))}

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      {/* <CookiesBanner /> */}
    </NavigatorWrapper>
  )
}
const NavigatorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100vw;
  background-color: var(--white);

  .main-content {
    flex: 1;
  }
  @media only screen and (max-width: 1100px) {
  }
`
