/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetcher } from 'Config/Fetcher'
import endpoints from '../../endpoints.json'
import { User } from './Types/User'
import { PasswordChangeObj } from './Types/PasswordChangeObj'

export const getUserData = createAsyncThunk(endpoints.getUserData, async (_: void, { rejectWithValue }) => {
  try {
    const response = await fetcher.get(endpoints.getUserData)
    return response.data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})

export const updateUserData = createAsyncThunk(endpoints.updateUserData, async (payload: User, { rejectWithValue }) => {
  try {
    const response = await fetcher.put(endpoints.updateUserData, payload)
    return response.data
  } catch (error: any) {
    return rejectWithValue(error.response?.data ? error.response?.data : { error: error.message })
  }
})

export const updatePassword = createAsyncThunk(
  endpoints.updatePassword,
  async (payload: PasswordChangeObj, { rejectWithValue }) => {
    try {
      const response = await fetcher.put(endpoints.updatePassword, payload)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data ? error.response?.data : { error: error.message })
    }
  },
)
