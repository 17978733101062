import { AuthState } from 'Contexts/Auth/Types/AuthState'
import { jwtDecode } from 'jwt-decode'

interface Props {
  token: string | null
  refresh: string | null
  // permissions: string[]
}
export const isJWTValid = async ({ token, refresh }: Props) => {
  if (!token || !refresh) {
    return false
  }
  try {
    const decodedToken: { userId: number; iat: number; exp: number } = jwtDecode(token)
    const currentDate = new Date()
    // Checking if the user has permission
    // if (!permissions.includes(decodedToken.permissions)) {
    //   console.error('Jwt Insufficient permissions')
    //   return false
    // }
    // console.log('fayfay decodedToken', decodedToken)
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.error('accessToken expired.')
      return false
    } else {
      return true
    }
  } catch (e) {
    console.error('invalid JWT: ', e)
    return false
  }
}

export const persistTokens = async ({
  accessToken,
  renewToken,
  accessTokenExpiryTime,
  renewTokenExpiryTime,
}: AuthState) => {
  accessToken && localStorage.setItem('accessToken', accessToken)
  renewToken && localStorage.setItem('renewToken', renewToken)
  accessTokenExpiryTime && localStorage.setItem('accessTokenExpiryTime', accessTokenExpiryTime)
  renewTokenExpiryTime && localStorage.setItem('renewTokenExpiryTime', renewTokenExpiryTime)
}

export const getTokens = () => {
  const accessToken = localStorage.getItem('accessToken')
  const renewToken = localStorage.getItem('renewToken')
  const accessTokenExpiryTime = localStorage.getItem('accessTokenExpiryTime')
  const renewTokenExpiryTime = localStorage.getItem('renewTokenExpiryTime')
  return { accessToken, renewToken, accessTokenExpiryTime, renewTokenExpiryTime }
}

export const removeTokens = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('renewToken')
  localStorage.removeItem('accessTokenExpiryTime')
  localStorage.removeItem('renewTokenExpiryTime')
}
