/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetcher } from 'Config/Fetcher'
import { RegistrationObj } from './Types/RegistrationObj'
import endpoints from 'endpoints.json'
import { LoginObj } from './Types/LoginObj'
import { baseURL } from 'Config/envUtils'

export const signOut = createAsyncThunk(endpoints.signOut, async (payload: undefined, { rejectWithValue }) => {
  try {
    const response = await fetcher.delete(`${baseURL}${endpoints.signOut}`, payload)
    return response.data
  } catch (error: any) {
    return rejectWithValue(error.response?.data ? error.response?.data : { error: error.message })
  }
})

export const sendRegistration = createAsyncThunk(
  endpoints.registration,
  async (payload: RegistrationObj, { rejectWithValue }) => {
    try {
      const response = await fetcher.post(`${baseURL}${endpoints.registration}`, payload)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data ? error.response?.data : { error: error.message })
    }
  },
)

export const login = createAsyncThunk(endpoints.signIn, async (payload: LoginObj, { rejectWithValue }) => {
  try {
    const response = await fetcher.post(`${baseURL}${endpoints.signIn}`, {
      email: payload.email,
      password: payload.password,
    })
    return response.data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})

export const sendPasswordForgottenEmail = createAsyncThunk(
  endpoints.sendPasswordForgottenEmail,
  async (payload: { email: string }, { rejectWithValue }) => {
    try {
      const response = await fetcher.post(endpoints.sendPasswordForgottenEmail, {
        email: payload.email,
      })
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  },
)
