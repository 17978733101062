/* eslint-disable @typescript-eslint/no-var-requires */
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import logoSVG from '../Assets/logo.svg'

interface LogoProps {
  width?: number
  to?: string
}
export const Logo = ({ width = 300, to }: LogoProps) =>
  to ? (
    <LogoWrapper>
      <Link to={to}>
        <img width={width} src={logoSVG} alt="logo" />
      </Link>
    </LogoWrapper>
  ) : (
    <LogoWrapper>
      <img width={width} src={require('../Assets/logo.svg').default} alt="logo" />
    </LogoWrapper>
  )

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
    object-fit: contain;
  }
`
