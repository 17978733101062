import { createSlice } from '@reduxjs/toolkit'
import { User, emptyUser } from './Types/User'
import { getUserData, updateUserData, updatePassword } from './User.interactor'

const userSlice = createSlice({
  name: 'user',
  initialState: emptyUser,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state: User, { payload }) => {
        state.admin = payload.data.admin
        state.email = payload.data.email
        state.emailNotificationsOn = payload.data.emailNotificationsOn
        state.firstName = payload.data.firstName
        state.lastName = payload.data.lastName
        console.log('New user state:', JSON.parse(JSON.stringify(state, null, 2)))
      })
      .addCase(updateUserData.fulfilled, (state: User, { payload }) => {
        state.admin = payload.data.admin
        state.email = payload.data.email
        state.emailNotificationsOn = payload.data.emailNotificationsOn
        state.firstName = payload.data.firstName
        state.lastName = payload.data.lastName
        console.log('New user state:', JSON.parse(JSON.stringify(state, null, 2)))
      })
    // .addCase(getUserData.pending, (state, action) => {})
    // .addCase(getUserData.rejected, (state, action) => {})
  },
})

export const userActions = {
  ...userSlice.actions,
  getUserData,
  updateUserData,
  updatePassword,
}
export default userSlice.reducer
