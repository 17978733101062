import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { startDownload } from 'Contexts/General/General.interactor'
import fileDownload from 'js-file-download'
import { PageHeader } from 'Common/PageHeader'
import { AxiosProgressEvent } from 'axios'
import { formatFileSize } from 'Config/functions'
import { ProgressBar } from 'Common/ProgressBar'
import { sendAnalyticsEvent } from 'Common/Analytics'
import { getLocalHistory } from 'Utils/localStorageUtils'
import { useSelector } from 'react-redux'
import { RootState } from 'Config/store'
import { ConversionItem, OperationType, SourceType } from 'Contexts/General/Types/ConversionItem'
import { Link } from 'react-router-dom'

export const Downloads = () => {
  const { t } = useTranslation()
  const [downloadedMb, setDownloadedMb] = useState<number>(0)
  const [localFileInfo, setLocalFileInfo] = useState<ConversionItem>()
  const [history, setHistory] = useState<ConversionItem[]>([])
  const [downloadingIndex, setDownloadingIndex] = useState<number | null>(null)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  useEffect(() => {
    if (!accessToken) {
      setHistory(getLocalHistory())
    } else {
      // Fetch history from server if logged in
      // setHistory(fetchServerHistory())
    }
  }, [accessToken])

  useEffect(() => {
    if (localFileInfo?.size && localFileInfo?.resultFilePath) downloadFile(localFileInfo?.resultFilePath)
  }, [localFileInfo?.size])

  const onDownloadProgress = async (progressEvent: AxiosProgressEvent) => {
    setDownloadedMb(progressEvent?.loaded ?? 0)
  }
  const downloadFile = async (filePath: string) => {
    if (!filePath) return console.log('fayfay no filePath')
    const res = await startDownload(filePath, onDownloadProgress)
    if (res?.data?.error) {
      if (res?.data?.error === '404') {
        setDownloadedMb(0)
      }
    } else {
      fileDownload(res, filePath)
      sendAnalyticsEvent({
        category: 'download',
        action: OperationType.DOWNLOAD,
        fileName: localFileInfo?.title ?? filePath ?? '-',
      })
      setDownloadingIndex(null)
    }
  }

  return (
    <DownloadsWrapper>
      <PageHeader title={t(`downloads-page.title`)} />
      <div className="content">
        <h3>{t('downloads-page.history-title')}</h3>
        <HistoryTableWrapper>
          <HistoryTable>
            <thead>
              <tr>
                <th>{t('downloads-page.history-date')}</th>
                <th>{t('downloads-page.history-file-name')}</th>
                <th>{t('downloads-page.history-file-size')}</th>
                <th>{t('downloads-page.file-source')}</th>
                <th>{t('downloads-page.history-operation-type')}</th>
                <th>{t('downloads-page.history-success')}</th>
                <th>{t('downloads-page.history-download-link')}</th>
              </tr>
            </thead>
            <tbody>
              {history.length === 0 ? (
                <tr>
                  <td colSpan={7}>{t('downloads-page.no-rows-found')}</td>
                </tr>
              ) : (
                history
                  .sort((a, b) => new Date(b.date ?? '').getTime() - new Date(a.date ?? '').getTime())
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.date ? new Date(item.date).toLocaleString() : '-'}</td>
                      <td>{item.title}</td>
                      <td>{item.size ? formatFileSize(item.size) : '-'}</td>
                      <td>
                        {item.sourceType === SourceType.YOUTUBE ? (
                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.url}
                          </a>
                        ) : (
                          t('downloads-page.local-file') + ': ' + item.originalFileName
                        )}
                      </td>
                      <td>{item.operationType}</td>
                      <td>{item.success ? t('general.success') : t('general.failure')}</td>
                      <td>
                        {downloadingIndex === index ? ( // Show progress bar if downloading
                          <div className="progress-container">
                            <ProgressBar progress={downloadedMb} total={localFileInfo?.size ?? 1} />
                          </div>
                        ) : (
                          <Link
                            to={''}
                            download
                            onClick={() => {
                              setLocalFileInfo(item)
                              setDownloadingIndex(index)
                            }}
                          >
                            {t('downloads-page.download')}
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </HistoryTable>
        </HistoryTableWrapper>
      </div>
    </DownloadsWrapper>
  )
}

const DownloadsWrapper = styled.div`
  background-color: var(--light-grey);
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 14px;
  .content {
    width: 100%;
    max-width: 100vw;
    overflow-x: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    .progress-container {
      width: 100%;
      max-width: 300px;
      min-height: 10px;
    }
  }
  .subtitle {
    font-size: 18px;
    span {
      font-weight: 500;
    }
  }
  a {
    color: var(--primary);
  }
  @media only screen and (max-width: 1100px) {
    .content {
      padding: 15px;
    }
    .subtitle {
      font-size: 16px;
    }
  }
`

const HistoryTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`

const HistoryTable = styled.table`
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  margin-top: 20px;
  th,
  td {
    border: 1px solid var(--dark-grey);
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: var(--primary);
    color: white;
  }
  td a {
    color: var(--primary);
    text-decoration: underline;
  }
  @media only screen and (max-width: 600px) {
    th,
    td {
      padding: 4px;
    }
    th {
      font-size: 12px;
    }
    td {
      font-size: 12px;
    }
  }
`
