import { styled } from "styled-components";

export const IsTypingAnim = () => {
  return (
    <IsTypingAnimWrapper>
      <div className="dot" />
      <div className="dot second" />
      <div className="dot third" />
    </IsTypingAnimWrapper>
  );
};

const IsTypingAnimWrapper = styled.div`
  display: flex;
  .dot {
    background-color: var(--white);
    margin: 4px;
    border-radius: 100%;
    width: 5px;
    height: 5px;
    animation: verticalTransition 2s ease-in-out infinite;
    &.second {
      animation-delay: 200ms;
    }
    &.third {
      animation-delay: 400ms;
    }
  }
  @keyframes verticalTransition {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
  @media only screen and (max-width: 1100px) {
  }
`;
