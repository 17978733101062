import React from "react";
import { styled } from "styled-components";

interface ProgressBarProps {
  progress: number;
  total: number;
}

export const ProgressBar = ({ progress, total }: ProgressBarProps) => {
  const percentage = (progress / total) * 100;

  return (
    <ProgressBarWrapper>
      <ProgressFiller style={{ width: `${percentage}%` }} />
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 20px;
  background-color: var(--grey);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
`;

const ProgressFiller = styled.div`
  height: 100%;
  background: linear-gradient(to left, var(--primary), var(--dark-primary));
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
`;
