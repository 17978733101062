import endpoints from 'endpoints.json'
import axios, { AxiosProgressEvent } from 'axios'
import { fetcher } from 'Config/Fetcher'
import { baseURL } from 'Config/envUtils'

export const getServerFileInfo = async (filePath: string) => {
  try {
    const endpoint = `${baseURL + endpoints.getServerFileInfo}`
    const body = { filePath }
    const response = await fetcher.post(endpoint, body)
    return response.data
  } catch (error: any) {
    return error?.response?.data
  }
}

export const startDownload = async (
  filePath: string,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
  try {
    const endpoint = `${baseURL + endpoints.download}`
    const body = { filePath: filePath }
    const response = await fetcher.post(endpoint, body, {
      responseType: 'blob',
      onDownloadProgress,
    })
    return response?.data
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: '404' } }
    }
    return { data: { error: true } }
  }
}

export const getYoutubeUrlInfo = async (url: string) => {
  try {
    const endpoint = `${baseURL + endpoints.youtubeInfo}`
    const body = { url }
    const response = await fetcher.post(endpoint, body)
    return response.data
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data
    }
    return { error: true }
  }
}
export const youtubeToAudio = async (url: string) => {
  try {
    const response = await fetcher.post(`${baseURL + endpoints.youtubeToAudio}`, { url })
    return response.data
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data
    }
  }
}

export const youtubeToTranscript = async (url: string) => {
  try {
    const endpoint = `${baseURL + endpoints.youtubeToTranscript}`
    const body = { url }
    const response = await fetcher.post(endpoint, body)
    return response.data
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data
    }
    return { error: true }
  }
}
export const fileToAudio = async (file: File) => {
  try {
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', file)
    const response = await fetcher.post(`${baseURL}${endpoints.fileToAudio}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return response.data
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: '404' } }
    }
    return { error: true }
  }
}

export const fileToTranscript = async (file: File, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
  try {
    const endpoint = `${baseURL}${endpoints.fileToTranscript}`
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', file)
    const response = await axios.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    return response?.data
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: '404' } }
    }
    return { error: true }
  }
}

export const youtubeToSummary = async (url: string) => {
  try {
    const endpoint = `${baseURL + endpoints.youtubeToSummary}`
    const body = { url }
    const response = await axios.post(endpoint, body)
    return response.data
  } catch (error: any) {
    if (error?.response?.data) {
      return error?.response?.data
    }
    return { error: true }
  }
}

export const fileToSummary = async (file: File, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
  try {
    const endpoint = `${baseURL}${endpoints.fileToSummary}`
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', file)

    const body = formData
    const response = await axios.post(endpoint, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    return response.data
  } catch (error: any) {
    if (error?.response?.status === 404) {
      return { data: { error: '404' } }
    }
    return { error: true }
  }
}
